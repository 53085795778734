<template>
    <div class="png_large_main" @click.stop v-if="imageData">
      <img src="../../assets/images/icon_white_close.svg" @click="hideSelf">
      <span>提示：电脑端访问可下载高清无水印原图</span>
      <div @wheel="handleWheel" ref="imageContainer" class="png_large_img" @click="hideSelf">
        <img :src="imageData.previewUrl" :style="{ transform: `scale(${scale})`, transition: 'transform 0.2s' }" @click.stop>
      </div>
      <div class="png_large_content">
        <!-- 右侧头部信息 -->
        <div class="png_popup_top">
          <!-- 广告 -->
          <div class="png_popup_ad" @click="openAdLink(randomMarketAd.link)">
            <span class="png_ad_identifier">由淘宝提供的广告</span>
            <div class="marketad_price" v-if="randomMarketAd.isAd">到手价{{ randomMarketAd.price }}元</div>
            <div class="png_popup_ad_mask">
              <button @click="copyMarketAdCode(randomMarketAd.code)"  @click.stop>复制淘口令</button>
            </div>
            <img :src="randomMarketAd.image" />
          </div>
          <!-- 按钮 -->
          <button class="large_download_btn"
            v-if="imageData.price > 0 && !isdownloadBtn"
            @click="showPictureFilesById(imageData)"
            :disabled="isDisabled"
            :class="{ 'button-disabled': isDisabled }"
            @click.stop>查看无水印原图 {{ imageData.price }}🍬
          </button>
          <button class="large_download_btn"
            v-else-if="imageData.price === 0 && !isdownloadBtn"
            @click="showPictureFilesById(imageData)"
            :disabled="isDisabled"
            :class="{ 'button-disabled': isDisabled }"
            @click.stop>查看无水印原图
          </button>

          <button v-if="isdownloadBtn" class="large_download_btn"
            @click="handleDownloadPng(imageData)"
            @click.stop>下载原图
          </button>
          <span>图片信息：AI生成 / {{ imageData.fileForma }} / {{ imageData.width }} x {{ imageData.height }} / {{ imageData.fileSize }} MB</span>
        </div>
        <!-- 组件标签 -->
        <!-- 在 tags 数组长度大于 0 且数组中的第一个元素不是空字符串时 -->
        <div class="png_tags_box" v-if="imageData.tags.length > 0 && imageData.tags[0] !== ''">
          <span>图片标签</span>
          <div class="tagsbox">
            <div
              @click="searchByKeyword(tag)"
              class="tags_item"
              v-for="tag in imageData.tags"
              :key="tag">{{ tag }}
            </div>
          </div>
        </div>
        <!-- 相似组件 -->
        <div class="png_similar_main" v-if="similarData.length > 0" >
          <span>相似图片 ( {{ similarData.length }} )</span>
          <div class="similarbox">
            <div
              class="similar_item"
              v-for="(item, index) in similarData"
              :key="index"
              @click="openSimilarWidget(item._id)">
              <img :src="item.cover">
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, defineEmits, provide, defineProps } from 'vue'
import { post, get } from '../../utils/request'
import { ElMessage } from 'element-plus'
import { useUserEffect } from '@/store/modules/useUserEffect'
// import { fetchTotalCount } from '../utils/utils'
import { useRouter } from 'vue-router'

defineProps({
  imageData: Object,
  similarData: Object
})
const router = useRouter() // 使用 useRouter 获取路由实例
const { userInfo } = useUserEffect()
const container = ref(null) // 弹窗图片返回顶部
provide('container', container)
const updateCandy = ref(0) // 加载顶部U糖初始值
const emit = defineEmits(['editWidget', 'hide', 'similarWidget', 'update-is-collected'])
const randomMarketAd = ref([]) // 随机广告

// 点击标签搜索
const searchByKeyword = (keyword) => {
  console.log(keyword)
  const route = {
    name: 'search',
    query: { type: 'png', q: keyword }
  }
  document.body.style.overflow = 'auto'

  router.push(route)
  hideSelf()
}

// 关闭弹窗
const handleKeyUp = (event) => {
  if (event.key === 'Escape') {
    hideSelf()
  }
}

// 通知父组件关闭弹窗
const hideSelf = () => {
  isdownloadBtn.value = false // 隐藏下载按钮
  emit('hide')
}

// 点击相似组件传递id通知父组件执行弹窗函数
const openSimilarWidget = (id) => {
  isdownloadBtn.value = false // 隐藏下载按钮
  emit('similarWidget', id)
}

// 页面加载时执行
onMounted(() => {
  getRandomMarketAd() // 获取广告
  window.addEventListener('keyup', handleKeyUp)
})
onBeforeUnmount(() => {
  window.removeEventListener('keyup', handleKeyUp)
})

// 重新加载顶部U糖数据
const reloadCandy = () => {
  updateCandy.value = Math.floor(Math.random() * 100) // 随机数变化更新组件
}

// 记录U糖变化
const createCandyLogBase = async ({ userId, pngId, type, copyType, candys }) => {
  const currentDate = new Date() // 获取中国北京时间（UTC+8）
  const offset = 8 * 60 // UTC+8 的分钟数
  const addTime = new Date(currentDate.getTime() + offset * 60 * 1000)
    .toISOString()
    .replace('Z', '+08:00')
  try {
    const formData = new FormData()
    formData.append('userId', userId)
    formData.append('pngId', pngId)
    formData.append('type', type)
    formData.append('copyType', copyType)
    formData.append('candys', candys)
    formData.append('addTime', addTime)
    const result = await post('/api/candyLog', formData)
    if (result?.errno === 0) {
      return
    }
  } catch (e) {
    console.error(e)
  }
}
// 记录用户U糖变化
const createCandyLog = async (imageData) => {
  const userId = userInfo._id
  const pngId = imageData._id
  const type = '查看AI原图'
  const copyType = false
  const candys = imageData.price
  await createCandyLogBase({ userId, pngId, type, copyType, candys })
}

// 获取1条广告
const getRandomMarketAd = async () => {
  try {
    const result = await get('/api/market-ad/random')
    if (result?.errno === 0 && result?.data) {
      // 为广告添加 isAd 标识
      randomMarketAd.value = {
        ...result.data,
        isAd: true // 添加广告标识
      }
    }
  } catch (error) {
    console.error(error)
  }
}

// 复制口令
const copyMarketAdCode = async (code) => {
  try {
    await navigator.clipboard.writeText(code)
    ElMessage({
      showClose: true,
      message: '淘口令已复制，打开淘宝App即可自动识别！',
      type: 'success',
      duration: 5000 // 0为不自动关闭，默认值3000
    })
  } catch (error) {
    console.error('复制失败:', error)
    ElMessage.error('复制失败，请重试')
  }
}

// 跳转广告
const openAdLink = (link) => {
  window.open(link, '_blank') // 在新窗口中打开对应的链接
}

// 获取原图地址
const isdownloadBtn = ref(false) // 下载按钮
const getPictureFilesById = async (imageData) => {
  const userId = userInfo._id

  // 验证是否登录
  const isLoggedIn = localStorage.getItem('isLogin') === 'true'
  if (!isLoggedIn) {
    ElMessage.warning('请先登录！每天登录可获得10U糖')
    return
  }

  // 判断U糖数量
  if (userInfo.candys < imageData.price) {
    ElMessage.error('U糖不足！每天登录可获得10U糖')
    return
  }

  try {
    // 扣除用户U糖
    const candyResult = await post(`/api/user/candys/${userId}`, {
      candys: imageData.price
    })
    if (candyResult?.errno === 0) {
      await createCandyLog(imageData) // 生成用户记录
      reloadCandy() // 重新加载顶部U糖数据
    } else {
      ElMessage.error('扣除U糖失败！')
      return
    }

    // 显示原图
    const result = await get(`/api/aipng/pictureFiles/${imageData._id}`)
    if (result?.errno === 0) {
      imageData.previewUrl = result.data.pictureFiles
      isdownloadBtn.value = true // 显示下载按钮
      ElMessage({
        showClose: true,
        message: '原图已更新，鼠标右键可复制或下载图片',
        type: 'success',
        duration: 5000 // 0为不自动关闭，默认值3000
      })
    }
  } catch (error) {
    ElMessage.error('下载失败，请稍后重试！')
  }
}

// 下载图片
const handleDownloadPng = async (imageData) => {
  try {
    // 使用 fetch 获取图片数据
    const response = await fetch(imageData.previewUrl)
    const blob = await response.blob()

    // 创建一个隐藏的 <a> 元素
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob) // 将 blob 转化为 URL
    link.download = imageData.previewUrl.split('/').pop() // 使用图片的文件名
    document.body.appendChild(link) // 将 <a> 元素添加到页面
    link.click() // 模拟点击触发下载
    document.body.removeChild(link) // 下载完成后移除 <a> 元素
  } catch (error) {
    ElMessage.error('下载失败，请稍后重试！')
  }
}

// 鼠标滚动缩放图片
const scale = ref(1)
const handleWheel = (event) => {
  // 阻止默认滚动行为
  event.preventDefault()

  // 计算缩放的增量
  if (event.deltaY < 0) {
    // 向上滚动，放大
    scale.value = Math.min(scale.value + 0.1, 3) // 限制最大缩放为3倍
  } else {
    // 向下滚动，缩小
    scale.value = Math.max(scale.value - 0.1, 0.5) // 限制最小缩放为0.5倍
  }
}

// 显示原图
const isDisabled = ref(false) // 允许点击状态
const showPictureFilesById = async (id) => {
  if (isDisabled.value) return

  isDisabled.value = true
  await getPictureFilesById(id) // 执行相关逻辑

  // *秒后重新允许点击
  setTimeout(() => {
    isDisabled.value = false
  }, 2500)
}

</script>

<style lang="scss" scoped>
@import "../../style/widget/aipngpopup.scss";
</style>
