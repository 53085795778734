<template>
  <!-- Safari浏览器提示 -->
  <div class="safari_tips" v-if="safariVisible">当前浏览器暂不支持在线复制组件，请使用其他浏览器</div>
  <!-- 手机端导航 -->
  <div class="navboxh5" v-if="showH5BottomTab">
    <nav>
      <router-link
      to="/"
      :key="$route.fullPath"
      :class="{ active:
        [
          '/my',
          '/aigc',
          '/thinking',
          '/appui',
          '/mobile',
          '/pc',
          '/element',
          '/template'
          ].includes($route.path)}">
        <div class="nav_item">
          <img v-if="[
            '/',
            '/my',
            '/aigc',
            '/thinking',
            '/appui',
            '/mobile',
            '/pc',
            '/element',
            '/template'
            ].includes($route.path)"
            src="../assets/images/icon_uizj_hover.svg">

          <img v-else  src="../assets/images/icon_uizj.svg">
          <span>首页</span>
        </div>
      </router-link>
      <router-link to="/analysis" :key="$route.fullPath">
        <div class="nav_item">
          <img v-if="$route.path === '/analysis'" src="../assets/images/icon_aicz_hover.svg">
          <img v-else src="../assets/images/icon_aicz.svg">
          <span>AI工具</span>
        </div>
      </router-link>
      <router-link to="/store">
        <div class="nav_item">
          <img v-if="$route.path === '/store'" src="../assets/images/icon_mbsd_hover.svg">
          <img v-else src="../assets/images/icon_mbsd.svg">
          <span>模板商店</span>
        </div>
      </router-link>
      <router-link to="/about" :key="$route.fullPath" >
        <div class="nav_item">
          <img v-if="$route.path === '/about'" src="../assets/images/icon_about_hover.svg">
          <img v-else src="../assets/images/icon_about.svg">
          <span>关于我们</span>
        </div>
      </router-link>
    </nav>
  </div>

  <!-- 搜索悬浮窗 -->
  <SearchPopup v-if="isTopSearchBox" @clear-history="handleClearHistory" @search="search"/>
  <!-- 用户信息悬浮窗 -->
  <UserInfoPopup v-if="isUserVisible" @mouseleave="hideUserInfo" @cancel="hideUserInfo"/>
  <!-- 工具悬浮菜单 -->
   <div class="tools_nav_mask" v-if="isToolsNav" @click="hideToolsNav">
    <div class="tools_nav" @mouseleave="hideToolsNav"><ToolsPopup /></div>
   </div>
   <!-- 广告弹窗 -->
   <!-- <div class="ad_popup_mask" v-if="isAdPopup">
    <div class="ad_popup">
      <img class="close_ad" src="../assets/images/icon_white_close.svg">
      <img class="ad_img" src="../assets/images/img_widget.jpg">
    </div>
    <button>查看详情</button>
   </div> -->

  <!-- 顶部导航栏模块 -->
  <div class="topbar">

    <div class="left_box">
        <div class="logobox" :class="{'notlogin': !isLoggedIn}">
        <!-- 根据不同路由显示不同LOGO -->
        <!-- <img v-if="route.path === '/home'" src="../assets/images/logo.svg" alt="反白Logo"> -->
        <router-link to="/"><img src="../assets/images/logo_norm.svg"></router-link>
      </div>
      <!-- 手机端logo -->
      <div class="logoboxh5"><img src="../assets/images/logo_norm_h5.svg"></div>
      <!-- 电脑端导航栏 -->
      <div class="navbox">
        <nav>
          <router-link to="/" :class="{ active: isActiveRoute }">首页</router-link>
          <router-link
            to="/app"
            :key="$route.fullPath"
            v-bind:class="{ active: $route.path === '/app' || $route.path === '/screen' || $route.path === '/all'} ">灵感
          </router-link>
          <router-link to="/article"
            :key="$route.fullPath"
            v-bind:class="{ active: $route.path === '/read' || $route.path === '/article'} ">阅读</router-link>
          <router-link to="/poster"
            :key="$route.fullPath"
            v-bind:class="{ active: $route.path === '/analysis' || $route.path === '/generator' || $route.path === '/table' || $route.path === '/poster'} " @mouseover="showToolsNav">工具
          </router-link>
          <router-link to="/about">关于</router-link>
        </nav>
        <!-- new标识-红点 -->
       <!-- <div class="readicon" v-if="newIcon"></div> -->
       <!-- AI标识 -->
       <div class="newicon">
        <img src="../assets/images/icon_aiicon.svg">
       </div>
       <!-- 三角形标识 -->
       <div class="more_icon">
        <img src="../assets/images/icon_nav_more.svg">
       </div>

        <!-- 顶部搜索栏 -->
        <div class="topsearch">
          <img class="searchicon" src="../assets/images/icon_sousuo.svg">
          <img class="clearicon"
            v-if="isTopSearchBox && searchTerm"
            @click="clearKeyword"
            src="../assets/images/icon_clearKeyword.svg">
          <img class="search_btn"
          v-if="isTopSearchBox"
          @click="handleSearch"
          src="../assets/images/icon_search_btn.svg">
          <input
            placeholder="搜索组件、图片、UI截图、App应用"
            v-model="searchTerm"
            @focus="showTopSearchBox"
            @keydown.enter="handleEnter">
        </div>
      </div>
    </div>

      <!-- 顶部右侧布局 -->
      <div class="right">
        <div class="store_btn">
           <!-- 历史记录 -->
          <img @click="showRecordBox" v-if="showRecord" src="../assets/images/icon_record_h.svg">
          <img @click="showRecordBox" v-else src="../assets/images/icon_record.svg">

          <!-- 模板商店 -->
          <router-link to="/store">
            <img v-if="$route.path === '/store'" src="../assets/images/icon_store_h.svg">
            <img v-else src="../assets/images/icon_store.svg">
          </router-link>
        </div>

        <!-- 收藏模块 -->
        <router-link to="/collect">
          <div class="collect"
            v-if="isLoggedIn"
            :key="$route.fullPath"
            v-bind:class="{ collectclick: $route.path === '/collect'}">
            <span v-if="totalCount > 0">{{ totalCount }}</span>
          </div>
        </router-link>

        <!-- 发布 -->
        <router-link to="/widget/create">
          <div v-if="createBtn" class="create_widget">
            <img src="../assets/images/icon_create_widget.svg">
            <span>发布</span>
          </div>
        </router-link>

        <!-- U糖 -->
        <router-link :to="{ name: 'user', params: { tab: 'candy' } }">
          <div class="candy" v-if="isLoggedIn">
          <img class="candy_box" src="../assets/images/icon_candy.svg">
          <span>{{userInfo.candys}}</span>
          </div>
        </router-link>

        <!-- 用户 -->
        <router-link :to="{ name: 'user', params: { tab: 'me' } }">
          <div class="userinfo"
          v-if="isLoggedIn"
          @mouseover="showUserInfo">
            <img :src="userInfo.imgUrl" >
          </div>
        </router-link>

        <!-- 登录注册 -->
        <button class="loginbtn" @click="openLogin" v-if="!isLoggedIn">登录 / 注册</button>
      </div>
    </div>

    <!-- 复制历史记录 -->
    <div class="copy_record_mask" v-if="showRecord" @click="hideRecordBox">
      <div class="copy_record_box" @click.stop>
        <div class="copy_record_top">
          <h1 v-if="recordWidget.length > 0">今日复制组件 {{ recordWidget.length }}</h1>
          <h1 v-else>今日复制组件</h1>
          <span>提示：当天复制的组件重复复制不消耗U糖，每天0点重置</span>
          <img src="../assets/images/icon_gb_black.png" @click="hideRecordBox">
        </div>
        <!-- 列表区 -->
        <div v-if="recordWidget.length > 0" class="copy_record_content">
          <!-- 组件item -->
          <div class="record_list_item" v-for="item in recordWidget" :key="item._id">
            <div class="record_widget_mask">
              <button
                @click="copyCode(item.widgetId._id)"
                @click.stop>复制组件
              </button>
            </div>
            <img v-lazy="item.widgetId?.image" @load="handleImageLoad"/>
            <!-- 标题、标签、作者 -->
            <div class="widget_title">
              <router-link :to="{ name: 'widgetdetails', params: { id: item.widgetId._id } }" target="_blank">
                <h1 title="组件详情">{{ item.widgetId?.title }}</h1>
              </router-link>
              <div class="list_tags" @click.stop>
                <div class="list_tags_item"
                @click="searchByKeyword(tag)"
                v-for="tag in item.widgetId?.tags"
                :key="tag">
                <span v-if="tag">#{{ tag }}</span>
                </div>
              </div>
              <!-- 作者和价格 -->
              <div class="widget_author_box">
                <div v-if="item.widgetId?.author" class="widget_author">
                  <router-link :to="{ name: 'userspace', params: { id: item.widgetId?.author._id } }" target="_blank">
                    <div class="widget_author_avatar">
                      <img :src="item.widgetId?.author.imgUrl">
                    </div>
                    <span>{{ item.widgetId?.author.nickname }}</span>
                  </router-link>
                </div>
                <div v-else class="widget_author_system">
                  <div class="widget_popup_avatar">
                    <div class="widget_author_avatar">
                      <img src="../assets/images/img_about_logo.png">
                    </div>
                  </div>
                  <span>UI大师官方</span>
                </div>
              </div>
            </div>
          </div>
        </div>
         <!-- 空内容显示 -->
        <div v-else class="copy_record_content">
        <div class="me_nullimg">
          <img src="../assets/images/null.svg">
          <span v-if="isLoggedIn">今天还没有复制过组件</span>
          <span v-else>登录后查看当天已复制的组件，重复复制不消耗U糖</span>
        </div>
      </div>
      </div>
    </div>

    <!-- 登录弹窗模块 -->
    <div class="loginmask" v-if="isLoginVisible" @close="closeLogin" @click="closeLogin">
      <div class="loginbox" @click.stop>
        <div class="loginbox_bg">
          <img src="../assets/images/loginbg.png">
        </div>

        <!-- 关闭弹窗 -->
        <div class="login_rightbox">
          <img class="closebox" @click="closeLogin" src="../assets/images/close_window.svg">

          <!-- 登录和注册切换 -->
          <div class="login_tab_item">
            <button
              @click="switchLoginType('logon')"
              :class="{ 'active': currentLoginType === 'logon' }">登录
            </button>
            <p></p>
            <button
              @click="switchLoginType('signup')"
              :class="{ 'active': currentLoginType === 'signup' }">注册
            </button>
          </div>
          <div class="login_input_box">
            <component :is="currentLoginComponent"></component>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="public_qrcode">
      <img src="../assets/images/gh_qrcode.jpg">
      <span>小程序上线</span>
    </div> -->
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router'
import { ref, onMounted, computed, provide, watch, nextTick, watchEffect, defineProps, defineEmits, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import LogOn from '../components/LogOn.vue'
import SignUp from '../components/SignUp.vue'
import SearchPopup from '../components/SearchPopup.vue'
import UserInfoPopup from '../components/UserInfoPopup.vue'
import ToolsPopup from '../components/nav/ToolsPopup.vue'
import { useUserEffect } from '@/store/modules/useUserEffect'
import { get, patch, post } from '../utils/request'
import { ElMessage } from 'element-plus'
import { fetchTotalCount } from '../utils/utils'
// import topnav from '@/store/modules/topnav'
// mixins: [topnav], // 使用 mixins 将逻辑混入组件

const router = useRouter()
const route = useRoute()
const store = useStore()
const { userInfo, getUserInfo } = useUserEffect()
const totalCount = computed(() => store.getters.getTotalCount) // 访问收藏总数
const searchTerm = ref(route.query.q || '') // 初始化 searchTerm
const isTopSearchBox = ref(false) // 顶部搜索弹窗
provide('isTopSearchBox', isTopSearchBox)
const searchHistoryKey = 'searchHistory'
const searchHistory = ref([]) // 搜索历史记录
provide('searchHistory', searchHistory)
const isUserVisible = ref(false) // 用户信息悬浮窗
const safariVisible = ref(false) // Safari浏览器
const currentType = ref(route.query.type || 'widget') // 当前的 type 参数
const newIcon = ref(true) // new标识
const isToolsNav = ref(false) // 工具导航菜单
const createBtn = ref(true) // 发布按钮
const columns = ref(2) // 默认列数
const array = ref([]) // 定义空数组存储元素高度
const showRecord = ref(false)
const recordWidget = ref([]) // 历史列表数据
const isLoggedIn = localStorage.getItem('isLogin')
const isLoginVisible = ref(false) // 弹窗遮罩
const currentLoginType = ref('logon') // 登录弹窗默认项
const props = defineProps({
  showLoginPopup: Boolean
})
const emit = defineEmits(['close-login'])
const showH5BottomTab = ref(true)

// 判断滚动到底部显示和隐藏H5底部标签
const handleScrollToH5 = () => {
  const scrollTop = window.scrollY || document.documentElement.scrollTop
  const windowHeight = window.innerHeight
  const docHeight = document.documentElement.scrollHeight

  if (scrollTop + windowHeight >= docHeight) {
    // 页面滚动到底部
    showH5BottomTab.value = false
  } else {
    showH5BottomTab.value = true
  }
}

// 判断路由显示和隐藏H5底部标签
const checkRouteAndScroll = () => {
  if (route.name === 'widget') {
    window.addEventListener('scroll', handleScrollToH5)
  } else {
    window.removeEventListener('scroll', handleScrollToH5)
  }
}

// 监听父组件showLoginPopup来执行openLogin
watch(() => props.showLoginPopup, (newVal) => {
  if (newVal) {
    openLogin()
  }
})

// 定义需要匹配的路径
const widgetRoutes = ['/', '/widgets', '/mobile', '/pc', '/element', '/template', '/type', '/my', '/thinking', '/appui', '/tob', '/graphic', '/aigc']

// 检查当前路径是否在 activeRoutes 中
const isActiveRoute = computed(() => widgetRoutes.includes(route.path))

// 显示登录弹窗
const openLogin = () => {
  isLoginVisible.value = true
  document.body.style.overflow = 'hidden'
}

// 关闭登录弹窗
const closeLogin = () => {
  isLoginVisible.value = false
  emit('close-login') // 通知父组件重置 showLoginPopup
  document.body.style.overflow = 'auto'
}

// 计算当前显示的登录或注册
const currentLoginComponent = computed(() => {
  return currentLoginType.value === 'logon' ? LogOn : SignUp
})

// 切换登录和注册类型的函数
const switchLoginType = (type) => {
  currentLoginType.value = type
}

// 在组件挂载时处理逻辑
onMounted(async () => {
  await getUserInfo()
  updateUserLastLoginTime() // 更新登录时间
  showNewIcon()
  showCreateBtn()
  nextTick(() => {
    getWaterfall()
  })
  searchTerm.value = route.query.q || ''
  // 历史记录的处理
  const history = localStorage.getItem(searchHistoryKey)
  if (history) {
    const parsedHistory = JSON.parse(history)
    if (parsedHistory.length > 0) {
      searchHistory.value = parsedHistory // 更新搜索历史记录
    }
  }

  // 监测是否为Safari浏览器
  function isSafari () {
    const ua = navigator.userAgent.toLowerCase()
    return ua.includes('safari') && !ua.includes('chrome')
  }
  // 检测是否是 Safari 浏览器
  if (isSafari() && route.name === '') {
    safariVisible.value = true
  } else {
    safariVisible.value = false
  }

  // 监听 userInfo._id 的变化并执行 fetchTotalCount
  watchEffect(() => {
    const userId = userInfo._id
    if (userId) {
      fetchTotalCount(userInfo._id, store)
    }
  })

  checkRouteAndScroll() // 初始化时检测当前路由并监听
  window.addEventListener('popstate', checkRouteAndScroll) // 当路由改变时重新检测
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScrollToH5)
  window.removeEventListener('popstate', checkRouteAndScroll)
})

// 返回首页
// const backHome = () => {
//   if (!isLoggedIn) {
//     try {
//       router.push('/home')
//     } catch (error) {
//       console.error('导航错误:', error)
//     }
//   }
// }
const clearKeyword = () => {
  searchTerm.value = ''
}

// 更新用户登录时间
const updateUserLastLoginTime = async () => {
  if (!isLoggedIn) {
    return
  }
  // 获取用户最后登录时间
  const lastLoginTime = new Date(userInfo.lastLoginTime)

  // 获取当前时间并转换为北京时间（UTC+8）
  const currentDate = new Date()
  const beijingTime = new Date(currentDate.getTime() + 8 * 60 * 60 * 1000) // 手动加上 8 小时偏移
  const formattedBeijingTime = beijingTime.toLocaleString('zh-CN', {
    timeZone: 'Asia/Shanghai',
    hour12: false // 24 小时制
  })
  // 提取日期部分（不包含时间）
  const lastLoginDate = lastLoginTime.toISOString().split('T')[0]
  const currentDateString = beijingTime.toISOString().split('T')[0]

  // 如果日期相同，跳出，不进行更新
  if (lastLoginDate === currentDateString) {
    return
  }

  // 如果日期不同，继续执行更新逻辑
  try {
    const id = userInfo._id
    const response = await patch(`/api/user/last-login-time/${id}`, {
      lastLoginTime: formattedBeijingTime
    })
    if (response?.errno === 0) {
      ElMessage.success('每日登录成功，+10🍬')
      userInfo.candys = response.data.candys // 更新U糖
      await createCandyLog() // 生成U糖日志
    } else {
      ElMessage.warning('更新失败')
    }
  } catch (error) {
    console.error('更新失败:', error.message)
  }
}

// 生成U糖日志
const createCandyLog = async () => {
  const userId = userInfo._id
  const type = '每天登录'
  const copyType = true
  const candys = '10'
  const currentDate = new Date() // 获取当前时间
  // 获取中国北京时间（UTC+8）
  const offset = 8 * 60 // UTC+8 的分钟数
  const addTime = new Date(currentDate.getTime() + offset * 60 * 1000)
    .toISOString()
    .replace('Z', '+08:00')
  try {
    const formData = new FormData()
    formData.append('userId', userId)
    formData.append('type', type)
    formData.append('copyType', copyType)
    formData.append('candys', candys)
    formData.append('addTime', addTime)
    const result = await post('/api/candyLog', formData)
    if (result?.errno === 0) {
      return
    }
  } catch (e) {
    console.error(e)
  }
}

// 显示new标识逻辑
const showNewIcon = () => {
  if (route.name === 'read') {
    newIcon.value = false
  }
}

// 显示发布按钮逻辑
const showCreateBtn = () => {
  if (route.name === 'createwidget' || route.name === 'editwidget') {
    createBtn.value = false
  }
}

// 显示顶部搜索弹窗逻辑
const showTopSearchBox = () => {
  isTopSearchBox.value = true
}
const hideTopSearchBox = () => {
  isTopSearchBox.value = false
}
// 点击不是输入框隐藏弹窗
const handlePageClick = (event) => {
  if (!event.target.closest('input')) {
    hideTopSearchBox()// 隐藏搜索悬浮框
  }
}
// 页面滚动事件隐藏弹窗
const handleScroll = () => {
  if (window.pageYOffset > 100) {
    hideUserInfo() // 隐藏用户悬浮框
    hideToolsNav()
    hideTopSearchBox() // 隐藏搜索悬浮框
    const input = document.querySelector('input') // 获取输入框
    if (input) {
      input.blur() // 让输入框失去焦点
    }
  }
}
window.addEventListener('scroll', handleScroll)
// 组件卸载时移除滚动事件监听器，防止内存泄漏

// 在组件挂载时添加页面点击事件监听器
watch(() => isTopSearchBox.value, (newValue) => {
  if (newValue) {
    document.addEventListener('click', handlePageClick)
  } else {
    document.removeEventListener('click', handlePageClick)
  }
})
// 输入框空时跳转路由
watch(searchTerm, (newValue) => {
  if (!newValue.trim()) {
    router.push({ name: 'widget', params: { tab: 'widget' } })
  }
})

// 监听路由变化并更新输入框的值
watch(() => route.query.q, (newQuery) => {
  if (newQuery) {
    searchTerm.value = newQuery || ''
  }
}, { immediate: true })

watch(route, (newRoute) => {
  currentType.value = newRoute.query.type || 'widget'
  searchTerm.value = newRoute.query.q || ''
}, { immediate: true })

// 显示用户悬浮窗逻辑
const showUserInfo = () => {
  isUserVisible.value = true
}
const hideUserInfo = () => {
  isUserVisible.value = false
}

// 显示和隐藏工具菜单
const showToolsNav = () => {
  isToolsNav.value = true
}
const hideToolsNav = () => {
  isToolsNav.value = false
}

// 搜索组件逻辑
const search = (term) => {
  // 导航到搜索结果页面，并传递搜索关键词作为参数
  router.push({ name: 'search', query: { type: currentType.value, q: term } })
  searchTerm.value = term
  isTopSearchBox.value = false // 隐藏弹窗
}

// 搜索触发逻辑
const handleSearch = () => {
  isTopSearchBox.value = false
  const input = document.querySelector('input') // 获取输入框
  if (input) {
    input.blur() // 让输入框失去焦点
  }
  const searchKeyword = searchTerm.value.trim()
  if (searchKeyword !== '') {
    // 获取历史搜索记录
    let history = localStorage.getItem(searchHistoryKey)
    history = history ? JSON.parse(history) : []
    history.unshift(searchKeyword) // 添加新的搜索关键词到历史记录

    // 限制搜索记录长度
    if (history.length > 28) {
      history = history.slice(0, 28)
    }

    // 存储到本地缓存
    localStorage.setItem(searchHistoryKey, JSON.stringify(history))
    search(searchTerm.value) // 调用搜索方法，并传入搜索关键词
    searchHistory.value = history // 更新搜索历史记录
  }
}
// 回车搜索
const handleEnter = (event) => {
  if (event.key === 'Enter') {
    handleSearch()
  }
}
// 清空搜索历史记录
const handleClearHistory = () => {
  localStorage.removeItem(searchHistoryKey)
  searchHistory.value = [] // 清空搜索历史记录
}

// 瀑布流布局方法
const getWaterfall = () => {
  // 选择所有带有类名 'list_item' 的元素
  const items = document.querySelectorAll('.record_list_item')
  // 如果没有找到任何元素，则返回
  if (items.length === 0) return

  // 初始化或重置列高度数组，将所有值设置为0，数组的长度为列数
  array.value = new Array(columns.value).fill(2)
  // 遍历每一个元素
  items.forEach((item, index) => {
    if (index < columns.value) {
      // 如果元素的索引小于列数，表示该元素属于第一行
      item.style.top = '20px' // 设置顶部位置
      item.style.left = `${item.clientWidth * index}px` // 设置左侧位置，按列数排列
      array.value[index] = item.clientHeight // 记录当前列的高度
    } else {
      // 如果元素的索引大于等于列数，表示该元素属于后续行
      const minHeight = Math.min(...array.value) // 找到当前最小的列高度
      const minIndex = array.value.findIndex(height => height === minHeight) // 找到最小高度的列索引
      item.style.top = `${minHeight + 25}px` // 设置顶部位置，距离最小高度列的底部
      item.style.left = `${items[minIndex].offsetLeft}px` // // 设置左侧位置，与最小高度列对齐
      array.value[minIndex] += item.clientHeight // 更新最小高度列的高度
    }
  })
}

// 图片加载完成后，重新计算布局
const handleImageLoad = () => {
  getWaterfall() // 重新计算瀑布流布局
}

// 显示历史记录抽屉
const showRecordBox = () => {
  getRecordWidget()
  nextTick(() => {
    getWaterfall()
  })
  showRecord.value = true
  document.body.style.overflow = 'hidden'
}
// 关闭历史记录抽屉
const hideRecordBox = () => {
  showRecord.value = false
  document.body.style.overflow = 'auto'
}

// 查询历史记录
const getRecordWidget = async () => {
  // 验证是否登录
  const isLoggedIn = localStorage.getItem('isLogin') === 'true'
  if (!isLoggedIn) {
    return
  }
  try {
    const userId = userInfo._id
    const result = await get(`/api/candyLog/record/${userId}`)
    if (result?.errno === 0 && result?.data) {
      recordWidget.value = result.data
    }
  } catch (error) {
    console.error('查询记录失败:', error.message)
    throw error
  }
}

// 复制组件逻辑
const copyCode = async (itemId) => {
  // 复制代码到剪贴板的通用函数
  const copyToClipboard = (code) => {
    const textarea = document.createElement('textarea')
    textarea.value = code
    textarea.style.position = 'fixed' // 避免在页面上滚动
    textarea.style.opacity = '0'
    document.body.appendChild(textarea)
    textarea.select()
    document.execCommand('copy')
    document.body.removeChild(textarea)
  }

  //  监测是否为Safari浏览器
  function isSafari () {
    const ua = navigator.userAgent.toLowerCase()
    return ua.includes('safari') && !ua.includes('chrome')
  }
  // 检测是否是 Safari 浏览器
  if (isSafari()) {
    ElMessage.error('当前浏览器暂不支持复制组件！')
    return
  }

  try {
    // 复制组件代码
    const result = await get(`/api/widget/code/${itemId}`)
    if (result?.errno === 0 && result.data.code) {
      ElMessage.success('组件已复制，请返回设计工具粘贴！')
      copyToClipboard(result.data.code)
    } else {
      ElMessage.error('复制失败!')
    }
  } catch (error) {
    ElMessage.error('复制组件异常，请稍后重试!')
  }
}

// 点击标签搜索
const searchByKeyword = (keyword) => {
  const newRoute = {
    name: 'search',
    query: { type: 'widget', q: keyword }
  }
  // 如果标签和当前路由q参数相同，不执行搜索
  if (newRoute.query.q === route.query.q) {
    ElMessage.warning('无需重复搜索！')
  } else {
    hideRecordBox()
    router.push({ name: 'search', query: { type: 'widget', q: keyword } })
  }
}

</script>

<style lang="scss">
@import '../style/topnav.scss'
</style>
